import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import CountUp from "react-countup";
import { walletsWidget } from "../../Common/data";
// import { GET_CLIENT_WASTE_NOTIFICATIONS } from "../../helpers/url_helper";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
// import { CompanyTypeEnum } from "../../helpers/enums/company.enum";
// import { toast } from "react-toastify";
import { getWalletMetrics as onGetWalletMetrics } from "../../slices/thunk";
// import { APIClient } from "../../helpers/api_helper";

const InvoiceWidget = () => {
  // const [state, setState] = useState<any>({});
  // const [walletBalance, setWalletBalance] = useState<{ [key: string]: any }>(
  //   {}
  // );
  // let navigate = useNavigate();

  const dispatch: any = useDispatch();
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Wallet,
    (state: any) => state.Login,
    (wallet: any, login: any) => ({
      tokens: login.tokens,
      user: login.user,
      metrics: wallet.metrics,
    })
  );
  const { tokens, user, metrics } = useSelector(selectAccountAndLogin);

  // const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
  //   // console.log("hasPermission user", user);
  //   return requiredTypes.includes(user?.company?.companyType);
  // };

  // const addLayoutData = (
  //   requiredTypes: CompanyTypeEnum[],
  //   child: any,
  //   alt: any = null
  // ) => {
  //   return hasPermission(requiredTypes) ? child : alt;
  // };

  // useEffect(() => {
  //   const getData = async () => {
  //     const api = new APIClient();
  //     try {
  //       let response = await api.get(
  //         `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/wallet-balance`,{}
  //         // { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
  //       );
  //       // console.log("response", JSON.parse(JSON.stringify(response)));
  //       const responseBody = JSON.parse(JSON.stringify(response));
  //       setWalletBalance(responseBody);
  //     } catch (error: any) {
  //       // console.error("error", error);
  //       console.log("typeof error", typeof error);
  //       if (typeof error == "string" && error.includes("401")) {
  //         const message = "Invalid or expired token";
  //         console.log("message", message);
  //         toast.error(message, { autoClose: 2000 });

  //         navigate(process.env.PUBLIC_URL + "/logout");
  //       }
  //     }
  //   };

  //   getData();
  // }, [navigate, tokens?.accessToken]);

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/wallet-metrics`,
  //         { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
  //       );
  //       // console.log("response", JSON.parse(JSON.stringify(response)));
  //       setState(JSON.parse(JSON.stringify(response)));
  //     } catch (error: any) {
  //       // console.error("error", error);
  //       console.log("typeof error", typeof error);
  //       if (typeof error == "string" && error.includes("401")) {
  //         const message = "Invalid or expired token";
  //         console.log("message", message);
  //         // toast.error(message, { autoClose: 2000 });

  //         navigate(process.env.PUBLIC_URL + "/logout");
  //       }
  //     }
  //   };

  //   getData();
  // }, [dispatch, navigate, tokens]);

  useEffect(() => {
    dispatch(onGetWalletMetrics());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Row>
        {walletsWidget.map((wasteNotificationWidgetData: any, key: any) => (
          <Col xl={4} md={4} key={key}>
            <Card className={`${wasteNotificationWidgetData.cardClass}`}>
              <Card.Body>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <h4
                      className={`fs-22 fw-semibold ff-secondary mb-2 ${wasteNotificationWidgetData.counterclass}`}
                    >
                      <CountUp
                        end={
                          metrics
                            ? metrics[`${wasteNotificationWidgetData.status}`]
                                ?.amount
                            : 0.0
                        }
                        // end={wasteNotificationWidgetData.counterValue}
                        decimals={2}
                        // suffix="k"
                        prefix="₦"
                      ></CountUp>
                    </h4>
                    <p
                      className={`text-uppercase fw-medium fs-14 ${wasteNotificationWidgetData.textClass}  mb-0`}
                    >
                      {wasteNotificationWidgetData.category}
                      <span
                        className={`text-${wasteNotificationWidgetData.arrrowTypeColor} fs-14 mb-0 ms-1`}
                      >
                        {wasteNotificationWidgetData.arrowTypeClass === "up" ? (
                          <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                        ) : (
                          <i className="ri-arrow-right-down-line fs-13 align-middle"></i>
                        )}{" "}
                        {wasteNotificationWidgetData.percentage}
                      </span>
                    </p>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-light rounded-circle fs-3">
                      <i
                        className={`${wasteNotificationWidgetData.catrgoryIcon} fs-24 text-primary`}
                      ></i>
                    </span>
                  </div>
                </div>
                {/* <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <span
                        className={`badge ${wasteNotificationWidgetData.badgeClass} me-1`}
                      >
                        {wasteNotificationWidgetData.categoryCount}
                      </span>{" "}
                      <span
                        className={`${wasteNotificationWidgetData.textClass}`}
                      >
                        
                        {wasteNotificationWidgetData.categoryCountText ===
                        "Waste Notifications Sent"
                          ? addLayoutData(
                              [
                                CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                                CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                                CompanyTypeEnum.PLATFORM_ADMIN,
                                CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                                CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                              ],
                              "Waste Notifications Received",
                              "Waste Notifications Sent"
                            )
                          : wasteNotificationWidgetData.categoryCountText}
                        {wasteNotificationWidgetData.categoryCountText}
                      </span>
                    </div>
                  </div> */}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default InvoiceWidget;
